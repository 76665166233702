// action types
import ApiService from "../api.service";
import {SET_ERROR} from "./auth.module";
export const SOCKET_API = process.env.VUE_APP_API_NODE_URL;
// Teacher
export const BRANCH_LIST = "branch/by-center";
export const TYPE_COURSE = "type-course";
export const COURSES = "courses";
export const CLASSROOMS = "classrooms";
export const GET_CENTER_BY_TEACHER_ID = "classrooms/get-center-by-teacher-id";
export const CHECK_LOGIN_TEACHER = "profile/ckeck-login-teacher";
export const CLASSROOM_DETAIL = "classrooms/detail";
export const GIFT = "game/gift";
export const GIVE_GIFT = "game/give-gift";
export const SET_TITLE = "setTitle";
export const CALL_TITLE = "storeTitle";

//OLd
export const HIERARCHY   = "branch/hierarchy";
export const REGISTER_WORK   = "working/register";
export const REGISTER_STORE   = "working/store";
export const REGISTER_TOTAL   = "working/total-register";
export const SELECT_LEADER   = "working/select-leader";
export const GET_LEADER   = "user/by-account";
export const TIME_KEEP   = "working/time-keep";
export const TIME_KEEP_ATTENDANCE   = "working/time-keep-attendance";
export const TIME_KEEP_TOTAL   = "working/total";

export const INFORMATION_SYSTEM   = "information/system";
export const INFORMATION_MECHANISM = "information/chinh-sach";
export const INFORMATION_HANDBOOK = "information/cam-nang";
export const INFORMATION_INFORMATION = "information/thong-bao";
export const SCHEDULE_DUTY = "working/schedule-duty";
export const SCHEDULE_DUTY_UPDATE = 'working/schedule-duty/update';
export const SCHEDULE_DUTY_STORE = 'working/schedule-duty/store';
export const SCHEDULE_DUTY_DELETE = 'working/schedule-duty/delete';

// Payslip
export const PAY_SLIP   = "payslip";
export const PAY_SLIP_TEACHER = "profile/payslip";
export const BOND_VALUE_DETAIL = "staff/bonds/all"

//course
export const GET_LIST_COURSE = "courses/get-list-course";
export const SHOW_QUANTURM_LEAP = "courses/get-quan-leap";
export const GET_CANDIDATE_LOGIN = "courses/get-candidate-login";
export const REDIRECT_QUANTURM_LEAP = "courses/redirect-quanturm-leap";
export const LIST_STUDENT_NOT_IN_CLAN = 'kids/clans/list-student-not-in-clan';
export const LIST_CHARACTERS_KID = 'kids/clans/list-characters';
export const CREATE_CLAN = 'kids/clans';
export const EDIT_CLAN = 'EDIT_CLAN'
export const GET_LIST_CLAN = 'kids/clans/by-classroom';
export const DELETE_CLAN = 'kids/clans/destroy';
export const LEAVE_ClAN = 'kids/clans/leave-the-team';
export const GIVE_EXP = 'kids/progressions/give-exps';
export const DETAIL_BOND_VALUE = 'staff/bonds/detail-by-milestone'


export const ID_SCHEDULE_SCHOOL_REVIEW = 43;

// mutation types

// state types
const state = {
    title: localStorage.getItem("className"),
};

const getters = {
    currentTitle(state) {
        return state.title;
    },
};

const actions = {
    [BRANCH_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${BRANCH_LIST}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [TYPE_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${TYPE_COURSE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [COURSES](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${COURSES}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CLASSROOMS](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${CLASSROOMS}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [GET_CENTER_BY_TEACHER_ID](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_CENTER_BY_TEACHER_ID}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CHECK_LOGIN_TEACHER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${CHECK_LOGIN_TEACHER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CLASSROOM_DETAIL](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${CLASSROOM_DETAIL}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },

    // Game- Tặng quà cho user
    [GIFT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${GIFT}`, payload)
                .then((data) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    // Game- Tặng quà cho user update
    [GIVE_GIFT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${GIVE_GIFT}`, payload)
                .then((data) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [HIERARCHY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${HIERARCHY}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [REGISTER_WORK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${REGISTER_WORK}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [REGISTER_STORE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${REGISTER_STORE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [SELECT_LEADER](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SELECT_LEADER}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [REGISTER_TOTAL](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${REGISTER_TOTAL}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [GET_LEADER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_LEADER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [TIME_KEEP](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${TIME_KEEP}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [TIME_KEEP_ATTENDANCE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${TIME_KEEP_ATTENDANCE}`, payload).then(({data}) => {
                resolve(data);
            }).catch(( {response} ) => {
                context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                resolve(response);
            });
        });
    },
    [TIME_KEEP_TOTAL](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${TIME_KEEP_TOTAL}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [INFORMATION_SYSTEM](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${INFORMATION_SYSTEM}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [INFORMATION_MECHANISM](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${INFORMATION_MECHANISM}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [INFORMATION_HANDBOOK](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${INFORMATION_HANDBOOK}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [INFORMATION_INFORMATION](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${INFORMATION_INFORMATION}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [PAY_SLIP](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${PAY_SLIP}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
    [PAY_SLIP_TEACHER](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${PAY_SLIP_TEACHER}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },
   
    [SCHEDULE_DUTY](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${SCHEDULE_DUTY}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [SCHEDULE_DUTY_UPDATE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SCHEDULE_DUTY_UPDATE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [SCHEDULE_DUTY_STORE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SCHEDULE_DUTY_STORE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [SCHEDULE_DUTY_DELETE](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`/${SCHEDULE_DUTY_DELETE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [PAY_SLIP](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${PAY_SLIP}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },

    [CALL_TITLE](context, payload) {
        context.commit(SET_TITLE, payload);
    },

    [GET_LIST_COURSE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_LIST_COURSE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [SHOW_QUANTURM_LEAP](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${SHOW_QUANTURM_LEAP}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [GET_CANDIDATE_LOGIN](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_CANDIDATE_LOGIN}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [REDIRECT_QUANTURM_LEAP](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${REDIRECT_QUANTURM_LEAP}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [LIST_STUDENT_NOT_IN_CLAN](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${LIST_STUDENT_NOT_IN_CLAN}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [LIST_CHARACTERS_KID](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${LIST_CHARACTERS_KID}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [CREATE_CLAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${CREATE_CLAN}`, payload)
                .then((data) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [GET_LIST_CLAN](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${GET_LIST_CLAN}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [EDIT_CLAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/kids/clans`, payload)
                .then((data) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [DELETE_CLAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${DELETE_CLAN}`, payload)
                .then((data) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [LEAVE_ClAN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${LEAVE_ClAN}`, payload)
                .then((data) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [GIVE_EXP](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/${GIVE_EXP}`, payload)
                .then((data) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    reject(response);
                });
        });
    },
    [DETAIL_BOND_VALUE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${DETAIL_BOND_VALUE}`, payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                });
        });
    },
    [BOND_VALUE_DETAIL](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`/${BOND_VALUE_DETAIL}`,payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(( {response} ) => {
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '');
                    resolve(response);
                });
        });
    },

};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_TITLE](state, title) {
        localStorage.setItem("className", title);
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
